import Footer from './Footer';
import Header from './Header/Header';

type LayoutProps = {
  children?: React.ReactNode;
  headerChildren: any;
};

const Layout: React.FunctionComponent<LayoutProps> = ({
  children,
  headerChildren,
}: LayoutProps) => {
  return (
    <>
      <Header
        headerChildren={headerChildren}
      />
      <div>{children}</div>
      <Footer />
    </>
  );
};

export default Layout;
