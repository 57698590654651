import React from "react";
import SearchStationsScreen from "../../Images/SearchStationsScreen.png";


const EaseOfUseCard = () => {
	
	return (
		<div className="flex items-center mt-12 md:mt-0  flex-row">
			<div className=" flex justify-center w-1/4">
				<img className="h-[18vh] md:h-[28vh] mt-8  w-[20vw] md:w-[12vw] " src={SearchStationsScreen}
				     alt="Logo"/>
			</div>
			<div className="  w-3/4">
				<h1
					className="flex text-lg md:text-3xl font-semibold text-green-900 items-center justify-center">
					Ease Of Use</h1>
				<p className="text-slate-600 pl-2  md:px-20">
					Our app is designed to be simple to use. Simply enter your current location or
					destination, and we'll show you a list of nearby charging stations. You can also filter
					the results by type of charger, availability, and price.
				
				</p>
			</div>
		</div>
	)
}


export {EaseOfUseCard}
