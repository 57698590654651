import {Button, Form, Input, notification, Radio, Space} from 'antd';
import React, {useCallback} from 'react';

const {TextArea} = Input;

type AdaptableGoogleFormsProps = {
	setSubmitted: (values: any) => void;
}

const SubmitFeedBackGoogleForm = (x: AdaptableGoogleFormsProps) => {
	const {setSubmitted} = x;
	
	
	const [form] = Form.useForm();
	const [api, contextHolder] = notification.useNotification();
	
	const onFinish = useCallback(
		async ({email, feedbackType, wouldRecommend, feedbackText, fullName, phoneNumber, permissionToContact}: {
			email: string;
			feedbackType: string;
			wouldRecommend: string
			feedbackText: string
			fullName: string;
			phoneNumber: string;
			permissionToContact: string;
		}) => {
			try {
				await fetch(
					'https://docs.google.com/forms/d/e/1FAIpQLSeKvd-BvQOf8GY1qcAtVCyQZVA-dHItNQ64lyilj5VZIvzdgw/formResponse?'
					+ new URLSearchParams({
						'entry.916488087': fullName,
						'entry.901239997': feedbackType,
						'entry.995624647': wouldRecommend,
						'entry.1922753315': feedbackText,
						'entry.2108020352': permissionToContact,
						'entry.1351233479': phoneNumber,
						emailAddress: email
					}),
					{
						mode: 'no-cors'
					}
				);
				api.success({
					message: 'Submitted successfully'
				})
				setTimeout(() => {
					setSubmitted(true);
				}, 2000)
				
			} catch (e: any) {
				api.error({
					message: e.message
				});
			}
		},
		[api, form]
	);
	
	
	return (
		<>
			<p className="flex items-center justify-center md:text-2xl mb-3 font-semibold text-green-900">Suggestion(s)
				Or
				Questions</p>
			<p className="text-gray-800 mb-3">Please let us know about your experience with our product and service,
				make a
				suggestions,
				or
				ask a
				question not covered in our FAQs</p>
			<p className="text-gray-800 mb-4 md:mb-7"><span className="text-amber-700">*</span> Indicates required
				question</p>
			
			{contextHolder}
			<Form
				form={form}
				layout="vertical"
				wrapperCol={{span: 18}}
				onFinish={onFinish}
			>
				<Form.Item
					name="fullName"
					label="Full Name"
					className="font-semibold text-gray-800"
					rules={[{required: true, message: 'Name  is required'}]}
				>
					<Input placeholder={'eg. Obinna Nwafo'}/>
				</Form.Item>
				<Form.Item
					name="phoneNumber"
					label="Phone Number"
					className="font-semibold text-gray-800"
					rules={[{required: true, message: 'Phone number  is required'}]}
				>
					<Input placeholder={'eg. 234-456-7890'}/>
				</Form.Item>
				<Form.Item
					name="email"
					label="Email"
					className="font-semibold text-gray-800"
					rules={[{required: true, message: 'Email address is required'}]}
				>
					<Input placeholder={'eg. someone@example.com'}/>
				</Form.Item>
				<Form.Item
					name="feedbackType"
					className="font-semibold text-gray-800"
					label="What Type of feedback are you giving?"
					rules={[{required: true, message: 'Feedback type is required'}]}
				>
					<Radio.Group>
						<Space direction="horizontal">
							<Radio
								className="font-normal text-gray-800"
								value="Suggestion">Suggestion</Radio>
							<Radio className="font-normal text-gray-800"
							       value="Question">Question</Radio>
						</Space>
					</Radio.Group>
				</Form.Item>
				<Form.Item
					name="wouldRecommend"
					className="font-semibold text-gray-800"
					label="Would you recommend it to your friends and colleagues?"
					rules={[{required: true, message: 'Your response is required'}]}
				>
					<Radio.Group>
						<Space direction="vertical">
							<Radio className="font-normal text-green-900"
							       value="Yes">Yes</Radio>
							<Radio className="font-normal text-amber-800"
							       value="No">No</Radio>
							<Radio
								className="font-normal text-gray-800"
								value="Maybe">Maybe</Radio>
						</Space>
					</Radio.Group>
				</Form.Item>
				<Form.Item
					name="permissionToContact"
					className="font-semibold text-gray-800"
					label="Can we contact you using details you provided?"
					rules={[{required: true, message: 'Your permission choice is required'}]}
				>
					<Radio.Group>
						<Space direction="vertical">
							<Radio className="font-normal text-green-900"
							       value="Yes">Yes</Radio>
							<Radio className="font-normal text-amber-800"
							       value="No">No</Radio>
						</Space>
					</Radio.Group>
				</Form.Item>
				<Form.Item
					name="feedbackText"
					className="font-semibold text-gray-800"
					
					label="What is your suggestion or question to improve our product and service?"
					rules={[{required: true, message: 'Your response is required'}]}
				>
					<TextArea
						rows={4}
						showCount
						className="font-normal text-gray-800"
						placeholder={'Type in your feedback here'}
						maxLength={1000}/>
				</Form.Item>
				
				<Button className="bg-green-800 font-semibold text-xs md:text-sm text-gray-100"
				        type="primary" htmlType="submit">
					Submit
				</Button>
			</Form>
		</>
	);
};

export {SubmitFeedBackGoogleForm};
