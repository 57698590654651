import React from 'react';
import MainContainer from './containers/MainContainer';
import {UserLocaleProvider} from './providers/UserLocale/UserLocale';
import {Provider} from 'react-redux';
import {coreStore} from "./store";

declare global {
    interface Window {
        recaptchaVerifier: any;
        confirmationResult: any;
        verificationCode: string;
    }
}
type AppProps = {
    props?: any;
};

const App: React.FunctionComponent<AppProps> = ({props}: AppProps) => {
    const locale = 'en-Us';
    const localeCopy = props?.localeMessages;


    return (
        <Provider store={coreStore}>
            <UserLocaleProvider locale={locale} defaultLocale='en' localeCopy={localeCopy}>
                <MainContainer/>
            </UserLocaleProvider>
        </Provider>
    );
};

export default App;
