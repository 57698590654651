import {FAQItemType} from "../../utils/app";

type FAQCardProps = {
	faqItem: FAQItemType
	
}


const FAQCard = ({faqItem}: FAQCardProps) => {
	const {question, answer} = faqItem;
	return <details className="flex px-2 md:px-4 py1 md:py-2 bg-gray-400 rounded my-1 md:my-2">
		<summary className="md:text-xl font-sans font-semibold text-green-900">{question}</summary>
		<p className="text-sm md:text-lg text-gray-100">{answer}</p>
	</details>
}

export {FAQCard}
