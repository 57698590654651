import React from "react";
import DLogo from '../../Images/3DLogo.png'
import {PressReleaseArticleType} from "../../utils/app";
import {getPublicationDate} from "./helpers/getPublicationDate";

type NewsCardProps = {
	pressRelease: PressReleaseArticleType;
}
const PressReleaseCard = ({pressRelease}: NewsCardProps) => {
	const {title, link, snippet, pubDate} = pressRelease;
	const publicationDate = getPublicationDate({publicationDate: pubDate})
	return (
		<div className="w-42 md:w-full drop-shadow-lg shadow-2xl bg-gray-200  rounded-2xl ">
			<div className="flex justify-center">
				<img className="h-16 md:h-16 mt-1 md:mt-3  rounded-xl w-28 md:w-16" src={DLogo} alt="Logo"/>
			</div>
			<div className="mx-3 mb-2 ">
				<div className="flex flex-col md:flex-row  py-1 md:py-3">
					
					<p className=" text-gray-500 text-2xs md:text-xs"><b>Release Date:</b> {publicationDate}</p>
				
				</div>
				<p className="text-xs md:text-sm font-serif pb-2 font-semibold text-gray-700">{title}</p>
				
				<p className=" text-sm md:text-base font-sans text-gray-900 line-clamp-5 ">{snippet}</p>
				
				<a href={link} target="_blank" rel="noreferrer" className="text-blue-400 font-semibold">Read
					more</a>
			</div>
		
		</div>
	);
	
}

export {PressReleaseCard}
