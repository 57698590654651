import PageBackground2 from "../Images/PageBackground2.png";
import {ContactUsContainer, ContinuousImprovementCard, EaseOfUseCard, MissionCard} from "../pageComponents/about";


const About = () => {
	
	return (
		<div style={{
			backgroundImage: `url(${PageBackground2})`, maxHeight: '3500vh'
		}}>
			<main>
				<div className=" ml-2 mr-1  md:mx-20 pb-20  min-h-[109vh]   md:px-6">
					<div className=" px-2 md:px-32    sm:px-0">
						<h1 className="flex pt-6 md:pt-8 justify-center text-green-900  text-2xl  font-semibold md:text-5xl">
							About Station Locate</h1>
						<MissionCard/>
						<ContinuousImprovementCard/>
						<EaseOfUseCard/>
						<ContactUsContainer/>
					</div>
				</div>
			</main>
		</div>
	);
};


export {About};
