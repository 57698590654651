import React from "react";
import {useIntl} from 'react-intl';
import {headerMessages} from '../../messages';

const {privacyPolicy, termsOfUse, accountDeletionPage, disclaimer} = headerMessages

type LegalsAndPoliciesProps = {
    buttonClicks: (buttonClicked: string) => void;
    source: string;
};

const LegalsAndPolicies: React.FunctionComponent<LegalsAndPoliciesProps> = ({
                                                                                buttonClicks, source
                                                                            }: LegalsAndPoliciesProps) => {
    const {formatMessage} = useIntl();
    const legalDropdownPosition = source === 'footer' ? 'origin-top-right block  justify-start -mt-[110px] md:-mt-[140px] w-24 md:w-40 -ml-[25px] md:ml-0  '
        : 'origin-bottom-left mt-[25px] md:mt-[38px] -ml-[28px] md:ml-0';
    return (
        <div
            className={`${legalDropdownPosition}  absolute  ml-1  px-[0.3rem] ${source !== 'footer' && 'md:w-36'}    rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu-button"
            tabIndex={-1}
        >

            <button
                className="   pt-1 md:pt-2  text-2xs md:text-sm text-gray-700 rounded-md  hover:bg-gray-300"
                type="button"
                onClick={() => {
                    buttonClicks('/privacy-policy');
                }}
            >
                {formatMessage(privacyPolicy)}
            </button>

            <button
                className="pt-1 md:pt-2 block  text-2xs md:text-sm text-gray-700 rounded-md  hover:bg-gray-300"
                type="button"
                onClick={() => {
                    buttonClicks('/terms-of-use');
                }}
            >
                {formatMessage(termsOfUse)}
            </button>
            <button
                className="pt-1 md:pt-2 block  text-2xs md:text-sm text-gray-700 rounded-md  hover:bg-gray-300"
                type="button"
                onClick={() => {
                    buttonClicks('/account-deletion');
                }}
            >
                {formatMessage(accountDeletionPage)}
            </button>

            <button
                className="pt-1 md:pt-2   text-2xs md:text-sm text-gray-700 rounded-md  hover:bg-gray-300"
                type="button"
                onClick={() => {
                    buttonClicks('/disclaimer');
                }}
            >
                {formatMessage(disclaimer)}
            </button>
        </div>
    );
};

export {LegalsAndPolicies};
