import {configureStore} from "@reduxjs/toolkit";
import React from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import App from './App';
import './index.css';

//Reducers
import {displayReducer} from './redux/reducers';
import reportWebVitals from './reportWebVitals';


//Store
const storeObj = configureStore({
	reducer: {
		allDisplayInfo: displayReducer
	}
});
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
	<React.StrictMode>
		<Provider store={storeObj}>
			<BrowserRouter>
				<App/>
			</BrowserRouter>
		</Provider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example, reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
