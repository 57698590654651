import {MultiSelectionInputItem, OtherAppsIUse} from "../../utils/app";

const otherAppsIUse: MultiSelectionInputItem[] = [
    {
        key: '1',
        value: 'plug-share' as OtherAppsIUse,
        label: 'Plug Share',
        selected: false,
    },
    {
        key: '2',
        value: 'ev-hotel' as OtherAppsIUse,
        label: 'Ev Hotel',
        selected: false,
    },
    {
        key: '3',
        value: 'charge-point' as OtherAppsIUse,
        label: 'Charge Point',
        selected: false,
    },
    {
        key: '4',
        value: 'charge-hub' as OtherAppsIUse,
        label: 'Charge Hub',
        selected: false,
    },
    {
        key: '5',
        value: 'volta' as OtherAppsIUse,
        label: 'Volta',
        selected: false,
    },
    {
        key: '6',
        value: 'electrify-america' as OtherAppsIUse,
        label: 'Electrify America',
        selected: false,
    },
    {
        key: '7',
        value: 'ev-go' as OtherAppsIUse,
        label: 'Ev Go',
        selected: false,
    },
    {
        key: '8',
        value: 'ev-connect' as OtherAppsIUse,
        label: 'Ev Connect',
        selected: false,
    },
    {
        key: '9',
        value: 'charge-way' as OtherAppsIUse,
        label: 'Charge Way',
        selected: false,
    },
    {
        key: '10',
        value: 'alt-fuel' as OtherAppsIUse,
        label: 'Alt Fuel',
        selected: false,
    },
    {
        key: '11',
        value: 'none' as OtherAppsIUse,
        label: 'None',
        selected: false,
    },
];

export {otherAppsIUse};
