import React, {useState} from "react";

import AppFAQ from "../Images/AppFAQ.png";
import FAQFeebackIcon from "../Images/FAQFeebackIcon.png";
import HomePageBackground from "../Images/HomePageBackground2.png";
import ReawardsFAQ from "../Images/ReawardsFAQ.png";

import {SubmitFeedBackGoogleForm} from "../pageComponents/commonComponents";

import {appFaqs, FAQCard, rankingFaqs} from "../pageComponents/faq";

type NewsBodyProps = {
	children?: React.ReactNode;
};


const Faqs = ({children}: NewsBodyProps) => {
	
	const [googleFormSubmitted, setGoogleFormSubmitted] = useState(false);
	
	return (
		<div style={{
			backgroundImage: `url(${HomePageBackground})`, maxHeight: '3500vh'
		}}>
			<main>
				{children}
				<div
					className=" ml-2 mr-1 mx-4 md:mx-20  py-6 min-h-[109vh]  md:px-6 lg:px-8"
				>
					<h3
						className="flex mx-2 justify-center text-green-900 md:tracking-wide font-sans antialiased text-xl md:text-4xl font-extrabold mb-6 md:mb-8"
					>Welcome To Station Locate FAQ</h3>
					
					<div className="flex justify-evenly items-center">
						<a id="#rewards" href="#" rel="noopener"
						   className="flex flex-col items-center">
							<img className="h-[8vh] md:h-[18vh]  " src={AppFAQ} alt="Frequently Asked Questions"/>
							<p className="text-green-900 md:text-3xl font-semibold font-serif  tracking-wide">
								App FAQs
							</p>
						</a>
						<a id="#rewards" href="#" rel="noopener"
						   className="flex flex-col items-center">
							<img className="h-[8vh] md:h-[18vh]  " src={ReawardsFAQ}
							     alt="Rewards Frequently Asked Questions"/>
							<p className="text-green-900 md:text-3xl font-semibold font-serif ">
								Ranking FAQs
							</p>
						</a>
						{!googleFormSubmitted &&
							<a id="#rewards" href="#feedback-form" rel="noopener"
							   className="flex flex-col items-center">
								<img className="h-[8vh] md:h-[18vh]  " src={FAQFeebackIcon}
									 alt="Rewards Frequently Asked Questions"/>
								<p className="text-green-900 md:text-3xl font-semibold font-serif ">
									Give Feedback
								</p>
							</a>
						}
					</div>
					<div className="px-4 md:ml-40 md:mr-20 py-6 md:mb-10 sm:px-0" id="app-faqs">
						<h3 className="flex justify-center text-green-900 md:tracking-wide font-sans antialiased text-xl md:text-4xl font-bold md:font-extrabold md:mb-8">
							App FAQs</h3>
						{appFaqs.map((faq, index) => (<FAQCard faqItem={faq} key={index}/>))}
					</div>
					<div className="px-4 md:ml-40 md:mr-20 py-6 md:mb-10 sm:px-0" id="ranking">
						<h3
							className="flex justify-center text-green-900 md:tracking-wide font-sans antialiased text-xl  md:text-4xl font-extrabold md:mb-8"
						>
							Ranking FAQs</h3>
						{rankingFaqs.map((faq, index) => (<FAQCard faqItem={faq} key={index}/>))}
					</div>
					<div className="px-4 md:ml-40 md:mr-20 md:py-6 sm:px-0" id="feedback-form">
						{!googleFormSubmitted &&
							<h3 className="flex justify-center text-green-900 md:tracking-wide font-sans antialiased text-xl  md:text-4xl font-extrabold md:mb-8">
								Submit Feedback</h3>
						}
						{googleFormSubmitted ?
							<p className="text-gray-600 md:text-xl">Your feedback is especially helpful. We'll
								definitely take it into consideration as we
								continue to develop our products and services.
								Thank you again for your feedback. We really appreciate it!</p> :
							<SubmitFeedBackGoogleForm
								setSubmitted={setGoogleFormSubmitted}/>}
					</div>
				
				</div>
			</main>
		</div>
	);
};

export {Faqs};
