import {FAQItemType} from "../../utils/app";

export const appFaqs = [
	{
		question: 'How does the app work?',
		answer: 'The app uses your location to find EV charging stations near you. You can also search for stations by address or type of charger.'
	},
	{
		question: 'How do I use the app?',
		answer: 'You can use the app to find EV charging stations near you. You can also search for stations by address or type of charger.'
	},
	{
		question: 'What types of EV charging stations are available?',
		answer: 'The app lists all types of EV charging stations, including Level 1, Level 2, and DC fast chargers.'
	},
	{
		question: 'How do I use the app to find a charging station?',
		answer: 'When you open the app, it automatically shows you the nearest stations. You can also search for stations using other criteria like zip code, street address, city, etc. The app will show you a list of nearby charging stations. You can also filter stations by type of charger, network they operate on, name, etc.'
	},
	{
		question: 'How can I get more information about the app?',
		answer: `You can visit our website or contact us at support@stationlocate.com`
	}
] as FAQItemType[]

export const rankingFaqs = [
	{
		question: 'How does Station Locate app  Ranking  work?',
		answer: 'You rank higher by adding useful information (eg comments, pictures, videos) about stations.'
	}
] as FAQItemType[]
