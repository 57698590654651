import React from "react";
import {useIntl} from 'react-intl';
import {footerMessages} from '../../messages';

type CompanyProps = {
    buttonClicks: (buttonClicked: string) => void;
};
const Company: React.FunctionComponent<CompanyProps> = ({
                                                            buttonClicks
                                                        }: CompanyProps) => {
    const {formatMessage} = useIntl();

    return (
        <div
            className="origin-top-right absolute  -mt-[70px] md:-mt-[80px]  w-24  md:w-28 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="user-menu-button"
            tabIndex={-1}
        >

            <button
                className="  px-2  md:px-4   text-2xs md:text-sm  text-gray-700 rounded-md  hover:bg-gray-300"
                type="button"
                onClick={() => {
                    buttonClicks('/about');
                }}
            >
                {formatMessage(footerMessages.about)}
            </button>

            <button
                className=" px-2  md:px-4   text-2xs md:text-sm text-gray-700 rounded-md  hover:bg-gray-300"
                type="button"
                onClick={() => {
                    buttonClicks('/in-the-news');
                }}
            >
                {formatMessage(footerMessages.news)}
            </button>
        </div>
    );
};

export {Company};
