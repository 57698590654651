import DLogo from '../../../Images/3DLogo.png'
import LATimesLogo from '../../../Images/LATimesLogo.png'
import {PublicationSources} from "../../../utils/app";


type PublicationImageToUseProps = {
	src: PublicationSources;
}

const publicationImageToUse = ({src}: PublicationImageToUseProps) => {
	switch (src) {
		case'station-locate':
			return DLogo
		case 'la-times':
			return LATimesLogo
		
		default:
			return DLogo
	}
	
}

export {publicationImageToUse};
