import PageBackground2 from "../Images/PageBackground2.png";
import React from "react";
import InfoIcon from "../Images/InfoIcon.png";


const BadVerificationEmailLinkInstructions = () => {

    return (
        <div style={{
            backgroundImage: `url(${PageBackground2})`, maxHeight: '3500vh'
        }}>
            <main>
                <div className=" ml-2 mr-1  md:mx-20 pb-20  min-h-[109vh]   md:px-6">
                    <div className=" px-2 md:px-32    sm:px-0">
                        <h1 className="flex pt-6 md:pt-10 10 md:pb-20  justify-center text-green-900  text-2xl  font-semibold md:text-5xl">
                            Why You Are Here</h1>

                        <div className="flex flex-row content-center">


                            <img className="h-[10vh] md:h-[10vh]  w-[10vw] md:w-[10vw] " src={InfoIcon}
                                 alt="Info Icon"/>
                            <div className="flex flex-col content-center">


                                <p className="text-gray-600 pl-2 text-sm md:text-xl  md:px-20">
                                    You recently signed up with an email and password, which requires that you verify
                                    your
                                    email before you can use the Station Locate app as a registered user. <b
                                    className="text-gray-700">However</b>, the verification email was <b
                                    className="text-gray-700">not</b> successfully sent to your email.


                                </p>
                                <p className="text-gray-600 pl-2  md:px-20 pt-10 text-sm md:text-xl">
                                    How is how to resolve this issue:
                                    <ol className='list-decimal ml-10 pl-2 mt-3 space-y-1  ml-05'>
                                        <li>Reopen the app</li>
                                        <li>On the dialogue that opens, tap "Resend verification email"</li>
                                        <li>Check your email, including your spam folder, for the verification email and
                                            confirm your email address
                                        </li>

                                    </ol>
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};


export {BadVerificationEmailLinkInstructions};
