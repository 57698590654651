import {Button, Checkbox, Form, Input, notification, Select} from 'antd';
import React, {useCallback} from 'react';
import {deletionReasons} from "./deletionResons";
import {otherAppsIUse} from "./otherAppsIUse";
import {AccountDeletionReasons, OtherAppsIUse} from "../../utils/app";

const {TextArea} = Input;

const SubmitAccountDeletionReqGoogleForm = () => {
    const [submitted, setSubmitted] = React.useState(false);

    const [acceptedCommunication, setAcceptedCommunication] = React.useState(false);

    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();

    const onFinish = useCallback(
        async ({
                   email,
                   selectedDeletionReasons,
                   otherAppsIUse,
                   feedbackText,
                   fullName,
                   phoneNumber,
                   communicationConsent
               }: {
            email: string;
            feedbackText: string
            fullName: string;
            phoneNumber: string;
            communicationConsent: string;
            selectedDeletionReasons: AccountDeletionReasons[];
            otherAppsIUse: OtherAppsIUse[];
        }) => {
            const usingPlugShare = otherAppsIUse.includes("plug-share").toString();
            const usingEVHotel = otherAppsIUse.includes("ev-hotel").toString();
            const usingChargePoint = otherAppsIUse.includes("charge-point").toString();
            const usingChargeHub = otherAppsIUse.includes("charge-hub").toString();
            const usingVolta = otherAppsIUse.includes("volta").toString();
            const usingElectrifyAmerica = otherAppsIUse.includes("electrify-america").toString();
            const usingEVGo = otherAppsIUse.includes("ev-go").toString();
            const usingEVConnect = otherAppsIUse.includes("ev-connect").toString();
            const usingChargeWay = otherAppsIUse.includes("charge-way").toString();
            const usingAltFuel = otherAppsIUse.includes("alt-fuel").toString();
            const deletionReasonMoving = selectedDeletionReasons.includes("moving-to-unsupported-region").toString();
            const deletionReasonTechnicalIssues = selectedDeletionReasons.includes("technical-issues").toString();
            const deletionReasonNeedMoreFeatures = selectedDeletionReasons.includes("need-more-features").toString();
            const deletionReasonHaveSimilarApps = selectedDeletionReasons.includes("have-similar-apps").toString();
            const deletionReasonLegalReasons = selectedDeletionReasons.includes("legal-reasons").toString();


            try {
                const deletionRequestTimestamp = new Date().getTime();
                await fetch(
                    'https://docs.google.com/forms/d/e/1FAIpQLSdjZalEX-32jMPaJchOWPfubAmrouYG1cY-0gWGLICcM1H5cQ/formResponse?'
                    + new URLSearchParams({
                        "entry.63572401": feedbackText,
                        "entry.811849170": deletionRequestTimestamp.toString(),
                        "entry.1109645253": communicationConsent,
                        "entry.344941941": fullName,
                        "entry.196283841": email,
                        "entry.519430320": phoneNumber,
                        "entry.1849107811": usingPlugShare,
                        "entry.1823706873": usingEVHotel,
                        "entry.588040828": usingChargePoint,
                        "entry.1928122888": usingChargeHub,
                        "entry.1633983871": usingVolta,
                        "entry.690090668": usingElectrifyAmerica,
                        "entry.842159448": usingEVGo,
                        "entry.1179071541": usingEVConnect,
                        "entry.1840709590": usingChargeWay,
                        "entry.1724429013": usingAltFuel,
                        "entry.537722566": deletionReasonMoving,
                        "entry.1009985641": deletionReasonTechnicalIssues,
                        "entry.186946870": deletionReasonNeedMoreFeatures,
                        "entry.1930510869": deletionReasonLegalReasons,
                        "entry.1262784803": deletionReasonHaveSimilarApps
                    }),
                    {
                        mode: 'no-cors'
                    }
                );
                api.success({
                    message: 'Submitted successfully'
                })
                setTimeout(() => {
                    setSubmitted(true);
                }, 2000)

            } catch (e: any) {
                api.error({
                    message: e.message
                });
            }
        },
        [api]
    );


    return (
        <>
            {contextHolder}
            {submitted && <p className="text-gray-600 mt-80 md:text-xl">Your feedback is especially helpful. We'll
                definitely take it into consideration as we
                continue to develop our products and services.
                Thank you again for your feedback. Someone will reach out to you if needed, and if you gave the
                permission for us to reach out to you.</p>}
            {!submitted && <>
                <p className="text-gray-800 text-2xl font-bold mb-10 md:mb-10"><span
                    className="text-amber-700">*</span> Indicates
                    required
                    field</p>
                <Form
                    form={form}
                    layout="vertical"
                    wrapperCol={{span: 18}}
                    onFinish={onFinish}
                >

                    <Form.Item
                        label="Deletion reason(s)"
                        name='selectedDeletionReasons'
                        className="font-semibold text-gray-800 w-1/3"
                        rules={[{required: true, message: 'Deletion reason is required'}]}
                    >
                        <Select
                            mode="multiple"
                            allowClear
                            style={{width: '100%'}}
                            placeholder="Please select"
                            defaultValue={[]}
                            options={deletionReasons}
                        />

                    </Form.Item>

                    <Form.Item
                        label="Other apps I use"
                        name='otherAppsIUse'
                        className="font-semibold text-gray-800 w-1/3"
                        rules={[{required: true, message: 'Other apps I use is required'}]}
                    >
                        <Select
                            mode="multiple"
                            allowClear
                            style={{width: '100%'}}
                            placeholder="Please select"
                            defaultValue={[]}
                            options={otherAppsIUse}
                        />

                    </Form.Item>


                    <p className="mb-8">
                        <span className='text-xl font-semibold underline'>Deletion Procedure</span> <br/>
                        To process your account/data deletion request, we need your full name and email as it appears on
                        the app, the email you used to open an account with us, and the phone number you used to verify
                        your identity while opening a account with us. These are needed to identify your account.

                        We will then send you an email for you to confirm your deletion request.
                        also need to confirm.

                        Please note that it might take up to 10 business days to complete account deactivation and
                        deletion unless where prohibited by law. Logging in as anytime within the next ten business days
                        (excluding holidays) will lead to cancellation of your deletion request. After the process is
                        complete, you will not be able to signup again with this email for at least 3 months. Please
                        visit our <a className='text-blue-700 hover:underline hover:text-blue-500' target='_blank'
                                     href={'https://www.stationlocate.com/privacy-policy'} rel="noreferrer"> Privacy
                        Policy </a> for more information.

                    </p>


                    <Form.Item
                        name="fullName"
                        label="Full Name"
                        className="font-semibold text-gray-800"
                        rules={[{required: true, message: 'Name  is required'}]}
                    >
                        <Input placeholder={'eg. Obinna Nwafo'}/>
                    </Form.Item>

                    <Form.Item
                        name="phoneNumber"
                        label="Phone Number"
                        className="font-semibold text-gray-800"
                        rules={[{required: true, message: 'Phone number  is required'}]}
                    >
                        <Input placeholder={'eg. 234-456-7890'}/>
                    </Form.Item>

                    <Form.Item
                        name="email"
                        label="Email"
                        className="font-semibold text-gray-800"
                        rules={[{required: true, message: 'Email address is required'}]}
                    >
                        <Input placeholder={'eg. someone@example.com'}/>
                    </Form.Item>


                    <Form.Item
                        name="feedbackText"
                        className="font-semibold text-gray-800"
                        label="How can we improve?"
                        rules={[{required: true, message: 'Your response is required'}]}
                    >
                        <TextArea
                            rows={4}
                            showCount
                            className="font-normal text-gray-800 "
                            placeholder='Tell us anything we could have done differently or better'
                            maxLength={1000}/>
                    </Form.Item>


                    <Form.Item
                        name="communicationConsent"
                        className="font-semibold text-gray-800"
                        label="Communication Consent"
                        valuePropName="checked"
                        rules={[{required: true, message: 'Your response is required'}]}
                    >
                        <Checkbox onChange={() => setAcceptedCommunication(!acceptedCommunication)}
                                  checked={acceptedCommunication}
                        >
                            <p
                                className="font-normal"
                            >
                                <span className="text-amber-700 font-normal">*</span>

                                I consent to electronic communications required to process my request.
                            </p>
                        </Checkbox>
                    </Form.Item>


                    <Button className="bg-red-600 font-semibold text-xs md:text-sm text-gray-100"
                            type="primary" htmlType="submit" onSubmit={() => onFinish}>
                        Submit
                    </Button>
                    <Button className=" ml-3 bg-green-800 font-semibold text-xs md:text-sm text-gray-100"
                            type="primary" htmlType="submit">
                        Changed my mind
                    </Button>
                </Form>
            </>
            }
        </>
    );
};

export {SubmitAccountDeletionReqGoogleForm};
