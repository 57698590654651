import {AccountDeletionReasons, MultiSelectionInputItem} from "../../utils/app";

const deletionReasons: MultiSelectionInputItem[] = [
    {
        key: '1',
        value: 'moving-to-unsupported-region' as AccountDeletionReasons,
        label: 'Moving to unsupported region',
        selected: false,
    },
    {
        key: '2',
        value: 'have-similar-apps' as AccountDeletionReasons,
        label: 'Have similar apps',
        selected: false,
    },
    {
        key: '3',
        value: 'need-more-features' as AccountDeletionReasons,
        label: 'Need more features',
        selected: false,
    },
    {
        key: '4',
        value: 'technical-issues' as AccountDeletionReasons,
        label: 'Technical issues',
        selected: false,
    },
    {
        key: '5',
        value: 'legal-reasons' as AccountDeletionReasons,
        label: 'Legal reasons',
        selected: false,
    },
];

export {deletionReasons};
