import React from "react";
import UserProfile from "../../Images/UserProfile.png";


const ContinuousImprovementCard = () => {
	
	return (
		<div className="flex flex-row-reverse items-center mt-10 md:mt-0  ">
			<div className="  w-1/4">
				<img className="h-[18vh] md:h-[30vh]  w-[20vw] md:w-[18vw] " src={UserProfile}
				     alt="Logo"/>
			</div>
			<div className="  w-3/4">
				<h1 className="flex text-lg md:text-3xl font-semibold text-green-900 items-center justify-center">
					Continuous Improvement</h1>
				<p className="text-gray-600 pl-2  md:px-20">
					We're constantly working to improve our app and add new features. If you have any
					feedback or suggestions, please don't hesitate to contact us.
				</p>
			</div>
		</div>
	)
}


export {ContinuousImprovementCard}
