import HomePageBackground from "../Images/HomePageBackground2.png";
import Policy from "./Policy";

const StationLocatePrivacyPolicy = () => {
	return <div
		style={{
			backgroundImage: `url(${HomePageBackground})`, maxHeight: '3500vh'
		}}
	>
		<div className="max-w-7xl mx-auto py-6  min-h-[109vh] max-h-[4000vh]sm:px-6 lg:px-8">
			<Policy/>
		</div>
	</div>
}

export {StationLocatePrivacyPolicy}
