import {Button, Form, Input, notification} from 'antd';
import React, {useCallback} from 'react';
import {useSelector} from "react-redux";
import {DisplayReducerType} from "../../utils/app";
import {displayStuff} from "../../redux/actionTypes";
import {dispatch} from "../../store";

const {DISPLAY_SET_SUBMITTED_NEWSLETTER_SUBSCRIPTION_FORM} = displayStuff

const SubmitSubscribeToNewsletterGoogleForm = () => {
    const allDisplayInfo = useSelector(
        (state: DisplayReducerType) => state.allDisplayInfo,
    );
    const {submittedNewsletterSignup} = allDisplayInfo;
    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();

    const onFinish = useCallback(
        async ({email,}: {
            email: string;
        }) => {
            try {
                await fetch(
                    'https://docs.google.com/forms/d/e/1FAIpQLSdCKqwfe-LjQZ3npx-ZTb1ZF_Q5tqHVT8U-jmF0iiRGGj9Zgw/formResponse?'
                    + new URLSearchParams({
                        emailAddress: email,
                        'entry.2099362831': 'fullName',
                    }),
                    {
                        mode: 'no-cors'
                    }
                );
                api.success({
                    message: 'Submitted successfully'
                })
                setTimeout(() => {

                    dispatch({
                        type: DISPLAY_SET_SUBMITTED_NEWSLETTER_SUBSCRIPTION_FORM,
                        payload: true,
                    })
                }, 2000)

            } catch (e: any) {
                api.error({
                    message: e.message
                });
            }
        },
        [api]
    );

    return (
        <>
            {!submittedNewsletterSignup && <p
                className="md:text-2xl py-2 ml-4 md:ml-10  antialiased font-semibold text-slate-600 ">
                Sign up for exclusive content and subscribe to our newsletter.
            </p>}
            {submittedNewsletterSignup && <p
                className="md:text-2xl py-2 ml-4 md:ml-10  antialiased font-semibold text-slate-600 ">
                Thank you for signing up for our newsletter! We're excited to share the latest news and updates with
                you 🎉 </p>}
            {contextHolder}

            {!submittedNewsletterSignup && <>
                <Form
                    form={form}
                    layout="vertical"
                    wrapperCol={{span: 18}}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="email"
                        className="font-semibold text-gray-800"
                        rules={[{required: true, message: 'Email address is required'}]}
                    >
                        <div className="flex  flex-row gap-3"
                        >
                            <Input className="md:w-[20vw] " placeholder='Email address'/>
                            <Button className="bg-green-800 font-semibold text-xs md:text-sm text-gray-100"
                                    type="primary" htmlType="submit">
                                Subscribe
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </>
            }
        </>
    );
};

export {SubmitSubscribeToNewsletterGoogleForm};
