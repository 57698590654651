import React from "react";
import GreenSuccessIcon from '../Images/GreenSuccessIcon.png'
import HomePageBackground from '../Images/HomePageBackground2.png'
import InfoIcon from '../Images/InfoIcon.png'
import MapsTabScreen from '../Images/MapsTabScreen.png'
import MapViewIcon from '../Images/MapViewIcon.png'
import MemberIcon from '../Images/MemberIcon.png'
import SearchIcon from '../Images/SearchIcon.png'


import StationMap from '../Images/StationMap.png'
import StationsCardList from '../Images/StationsCardList.png'
import UserProfile from '../Images/UserProfile.png'
import {SubmitSubscribeToNewsletterGoogleForm} from "../pageComponents/home";


type HomeBodyProps = {
    children?: React.ReactNode;
};

const Home: React.FunctionComponent<HomeBodyProps> = ({children}: HomeBodyProps) => {


    const listIcons = 'h-3 md:h-6 w-3 md:w-6  mx-0.5 md:mx-2 mr-1'
    const listContainer = 'flex rounded md:rounded-xl   flex-row mt-1 px-0.5 py-1 bg-gradient-to-r '

    return (
        <div
            style={{
                backgroundImage: `url(${HomePageBackground})`, maxHeight: '109vh'
            }}
        >
            <main>
                {children}
                <div
                    className=" md:grid  md:mx-10 md:grid-cols-2  min-h-[109vh] max-h-[4000vh]  md:px-6 lg:px-8 ">
                    <div className=" px-2 md:px-4 w-full  sm:px-0">
                        <div className="">
                            <p className="text-2xl pt-2 md:pt-12 md:text-6xl ml-4 md:ml-10 antialiased font-semibold text-stone-50">
                                The easiest way to
                            </p>
                            <p className="text-2xl md:text-6xl md:pt-4 ml-4 md:ml-10 antialiased font-semibold text-green-900">
                                find EV charging stations!
                            </p>

                        </div>
                        <div>
                            <p className="md:text-3xl py-2 pr-2 md:py-11 ml-4 md:ml-10 antialiased font-semibold text-slate-600">
                                Our app makes it easy to find the nearest charging station no matter where you are.
                                With features like map directions, and user reviews, you can be sure
                                you'll always find a reliable charging station when you need it. </p>

                        </div>
                        <div
                            className=" flex  md:mt-2  items-center justify-center flex-row ">
                            <a href="https://apps.apple.com/us/app/station-locate/id6469472521?itsct=apps_box_badge&amp;itscg=30200"
                               target='_blank'
                               rel="noreferrer"

                            >
                                <img
                                    className='rounded-xl h-12 md:h-24 w-32 md:w-64'
                                    src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1697673600"
                                    alt="Download on the App Store"/></a>
                            <a href='https://play.google.com/store/apps/details?id=com.stationlocatemobilev10&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                               target='_blank'
                               rel="noreferrer"
                            >
                                <img
                                    className='rounded-xl h-16 md:h-32 w-36 md:w-72'
                                    src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
                                    alt='Get it on Google Play'/></a>

                        </div>


                        <div className="flex items-center justify-center flex-col  mb-10  md:mt-[10vh]">
                            <SubmitSubscribeToNewsletterGoogleForm/>

                        </div>


                    </div>


                    <div className="md:flex md:ml-2 md:flex-col sm:px-0">
                        <div className="flex flex-row items-center justify-center  mt-4 mx-8 md:mx-0 sm:px-0">

                            <div className="w-1/3">
                                <img className="h-[20vh] md:h-[38vh]  " src={StationsCardList} alt="Logo"/>
                            </div>
                            <div className="  w-1/3">
                                <img className="h-[20vh] md:h-[38vh]  " src={StationMap} alt="Logo"/>
                            </div>
                            <div className="w-1/3 md:w-1/3">
                                <img className="h-[20vh] md:h-[38vh] w-[38vw] md:w-[38vw] mr-1" src={MapsTabScreen}
                                     alt="Logo"/>

                            </div>
                        </div>
                        <div className="flex flex-row sm:px-0">

                            <div className="ml-2 md:ml-6 mr-1">
                                <ul className="mt-2 p-3 text-xs md:text-lg text-white ">
                                    <li className={`${listContainer} items-center  from-green-800 to-indigo-400 `}>
                                        <img className={`${listIcons}`} src={SearchIcon} alt="Logo"/>
                                        <p>Find stations nearby, or a different address anywhere in North
                                            America</p>
                                    </li>
                                    <li className={`${listContainer} items-center  from-sky-400 to-indigo-400`}>
                                        <img className={`${listIcons}`} src={MapViewIcon} alt="Logo"/>
                                        <p>View results on a map, or as a list, your choice!</p>
                                    </li>
                                    <li className={`${listContainer} items-center from-violet-400 to-fuchsia-400`}>
                                        <img className={`${listIcons}`}
                                             src={InfoIcon}
                                             alt="Logo"/>
                                        <p>View detailed information about a chosen station, call the location or
                                            provider for more information</p>
                                    </li>
                                    <li className={`${listContainer} from-green-800 to-fuchsia-400`}>
                                        <img className={`${listIcons} mt-3`} src={MemberIcon} alt="Logo"/>
                                        <div>
                                            <p>Become a member for more features like </p>
                                            <ul className="ml-5 md:text-sm ">
                                                <li className=" flex flex-row mt-1 items-center ">
                                                    <img className="h-3 w-3 mr-2 " src={GreenSuccessIcon} alt="Logo"/>
                                                    Save locations (home, office, etc) for quick
                                                    searches
                                                </li>
                                                <li className=" flex flex-row mt-1 items-center ">
                                                    <img className="h-3 w-3 mr-2 " src={GreenSuccessIcon} alt="Logo"/>
                                                    See a station you like? Add it to your favorites!
                                                </li>
                                                <li className=" flex flex-row mt-1 items-center ">
                                                    <img className="h-3 w-3 mr-2 " src={GreenSuccessIcon} alt="Logo"/>
                                                    Add your car**
                                                </li>
                                                <li className=" flex flex-row mt-1 items-center ">
                                                    <img className="h-3 w-3 mr-2 " src={GreenSuccessIcon} alt="Logo"/>
                                                    Get helped from highly ranked members
                                                </li>
                                                <li className=" flex flex-row mt-1 items-center ">
                                                    <img className="h-3 w-3 mr-2 " src={GreenSuccessIcon} alt="Logo"/>
                                                    User reviews
                                                </li>
                                                <li className=" flex flex-row mt-1 items-center ">
                                                    <img className="h-3 w-3 mr-2 " src={GreenSuccessIcon} alt="Logo"/>
                                                    Being part of a pioneer community
                                                </li>
                                            </ul>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                            <div>
                                <img className="h-[32vh] w-[32vw] hidden md:block mr-1" src={UserProfile} alt="Logo"/>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};
export default Home;
