import React from "react";
import DLogo from "../../Images/3DLogo.png";


const MissionCard = () => {
	
	return (
		<div className="flex flex-row mt-2 md:mt-0 items-center">
			<div className=" flex  justify-center w-1/4">
				<img className="h-[10vh] md:h-[25vh] mt-10  w-[18vw] md:w-[10vw] " src={DLogo} alt="Logo"/>
			</div>
			<div className="  w-3/4">
				<h1 className="flex text-lg md:text-3xl font-semibold text-green-900 items-center justify-center">Our
					Mission</h1>
				<p className="text-gray-600 pl-2  md:px-20">
					To facilitate mass adoption of electric vehicles and motorbikes by
					delivering <b className="text-gray-700">accurate</b> results from station searches in
					a <b className="text-gray-700">reliable</b> and <b className="text-gray-700">meaningful</b> way to
					everyone.
				</p>
			</div>
		</div>
	)
}


export {MissionCard}
