import React from "react";
import StationLocateLogo512 from "../../Images/3DLogo.png";
import {HeaderTabsItemsDesktop} from "./headerItems";

type HeaderProps = {
	headerChildren?: React.ReactNode;
};

const Header = ({
	                headerChildren
                }: HeaderProps) => {
	
	const headerBackgroundArray = ['bg-gradient-to-r from-green-300 to-fuchsia-300',
		'bg-gradient-to-r from-fuchsia-300 to-green-300',
		'bg-gradient-to-r from-purple-300 to-violet-300',
		'bg-gradient-to-r from-violet-300 to-fuchsia-300']
	
	const randomizedHeaderBackgrounds = headerBackgroundArray[Math.floor(Math.random() * headerBackgroundArray.length)]
	
	return (
		<div className="flex flex-col w-auto sticky  top-0 z-10">
			<nav className="bg-[#065F46]">
				<div className=" mx-1 md:mx-auto px-0 md:px-4  lg:px-8">
					<div className="flex  justify-center h-16 md:mr-2">
						<div className="flex ">
							<div className="flex items-center">
								<img
									className=" ml-2 mr-1 md:mr-2 md:ml-0 md:flex h-5 md:h-8  w-5 md:w-8 md:align-center"
									src={StationLocateLogo512}
									alt="Station Locate Logo"
								/>
								<span
									className="  md:ml-0.5 md:block text-2xs md:text-base md:font-medium text-gray-200">
                      Station Locate App
                      </span>
							</div>
							<HeaderTabsItemsDesktop
							/>
						</div>
					
					</div>
				</div>
			</nav>
			
			<header className={`flex items-center shadow ${randomizedHeaderBackgrounds}}`}>
				<div className="mx-auto py-1 md:py-2 px-4 sm:px-6 lg:px-8 ">
					{headerChildren}
				</div>
			</header>
		</div>
	);
};

export default Header;
